<template>
    <div class="charts">
        <div :id="'pieChart'+ ids" style="width: 320px;height: 120px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props: {
        pieList: Array,
        ids: String,
    },
    data() {
        return {
            pieChart: null,
        };
    },
    mounted() {
        let arr = []
        this.pieList.forEach(ele => {
            arr.push(ele.color)
        })
        this.colorList = arr
        this.getEchat()
    },
    methods: {
        // 绘图
        getEchat() {
            var that = this
            that.pieChart = echarts.init(document.getElementById(`pieChart${this.ids}`));
            const option = {
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 0,
                },
                legend: {
                    icon: "circle",
                    orient: "vertical",
                    itemHeight: 8,
                    itemWidth: 8,
                    right: "0px",
                    y: 'center',
                    textStyle: {
                        color: "#333333",
                        fontSize: 13,
                    },
                    formatter: function (labelName) {
                        const { pieList } = that
                        for (let index = 0; index < pieList.length; index++) {
                            const { value } = pieList[index];
                            if (pieList[index].name == labelName) {
                                return `${labelName} :  ${value}   `
                            }
                        }
                    }
                },
                series: [{
                    color: this.colorList,
                    type: 'pie',
                    radius: ['55%', '80%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: that.pieList
                }]
            };
            that.pieChart.setOption(option);
        },
    },
};
</script>

<style scoped lang="scss">
.charts {
    margin-left: -60px;
}
</style>
